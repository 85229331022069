import { AuthGuard } from 'src/guards/auth-guard';
import { BillingGuard } from 'src/guards/billing-guard';
import { ClaimsAndReturnsGuard } from 'src/guards/claims-returns-guard';

export const withAuthGuard = (Component) => (props) => (
  <AuthGuard>
    <BillingGuard>
      <ClaimsAndReturnsGuard>
        <Component {...props} />
      </ClaimsAndReturnsGuard>
    </BillingGuard>
  </AuthGuard>
);
