import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';

export const ClaimsAndReturnsGuard = (props) => {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (isAuthenticated) {
      if (process.env.REACT_APP_IS_CLAIMS_ACTIVE !== "true") {
        if (window.location.href.includes("/claims")) {
          router.replace(paths.index);
          return;
        }
      }
      if (process.env.REACT_APP_IS_RETURNS_ACTIVE !== "true") {
        if (window.location.href.includes("/returns")) {
          router.replace(paths.index);
          return;
        }
      }
      setChecked(true);
    } else {
      const searchParams = new URLSearchParams({
        returnTo: window.location.href,
      }).toString();
      const href = window.location.origin + `?${searchParams}`;
      router.replace(href);
    }
  }, [isAuthenticated, router]);
  
  useEffect(() => {
      check();
    },
    []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};

ClaimsAndReturnsGuard.propTypes = {
  children: PropTypes.node
};
